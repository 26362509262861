import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  TableContainer,
} from "@cambridgeassessment/cambridge-ui";
import { SvgIcon } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { constants } from "../../constants";
import { getCandidateHistory, getStudentInfo } from "../../services/muleSoftServices";
import "./StudentDetail.css";
import { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { setAlert } from "../../redux/slice/GlobalMessage";
import { isEmpty } from "lodash";

const StudentDetail = () => {
  const {
    state: {
      currentStudent: { candidateNumber,studentName,studentStateId,DOB,components,nationalId,cambridgeId },
      series,
      reDirectTo,
      tab,
      onPageRecs,
      recIndex,
      selectedParams,
      centName,
      centreId,
      districtId,
      stateId
    }
  } = useLocation();
  const initialState={
          candNumber:candidateNumber,
          stuName:studentName,
          stuStateId:studentStateId,
          dob:DOB,
          natId:nationalId,
          caId:cambridgeId
        },
        [initialData, setInitialData] = useState(initialState),
        navigate = useNavigate(),
        dispatch = useDispatch(),
        studentDetialRef = useRef(null),
        previousObj = useRef({}),
        nextObj = useRef({}),
        centreName = useRef(""),
        historyCheck = useRef(false),
        [downloadBtn,setDownloadBtn] = useState(""),
        [recComponents,setRecComponents] = useState(""),
        userInfo = useSelector(state => state.userInfo),
        supportRole = useSelector(state => state.new);
  useEffect(()=>{
    window.scrollTo(0,0);   
    if(recIndex>=0){
      studentSwitching("initial");
    }else if(components?.length>0){
      setRecComponents(components);
    }
    
    if(userInfo.roleLevel !== 'state'){
      if(selectedParams?.centre?.centreName){
        centreName.current = selectedParams.centre.centreName;
      }else if(centName){
        centreName.current = centName;
      }else{
        centreName.current = !isEmpty(userInfo.emlCentreObj)?userInfo.emlCentreObj?.centreName:userInfo?.orgs[0]?.name;
      }            
    }
  
    if(userInfo.roleLevel === 'exams' || userInfo.roleLevel === 'head' || userInfo.emlCentre){
      setDownloadBtn("Go to data downloads");
    }else if(userInfo.roleLevel === 'district' || userInfo.roleLevel === 'state' || 
             userInfo.emlDistrict || userInfo.emlState){
      setDownloadBtn("Download school data report");
    }
  },[])

  const studentSwitching = (btn,currentIndex) =>{
    let cIndex = btn === "initial"? recIndex:currentIndex;
    historyCheck.current = false;    
    if(cIndex === 0 && onPageRecs.length>1){
      previousObj.current = {show:false};
      nextObj.current = {
        show:true,
        stuName:onPageRecs[cIndex+1].studentName,
        index:cIndex+1
      }
    }else if(cIndex === onPageRecs.length-1 && onPageRecs.length>1){
      previousObj.current = {
        show:true,
        stuName:onPageRecs[cIndex-1].studentName,
        index:cIndex-1
      };
      nextObj.current = {show:false};
    }else if(onPageRecs.length>=3){
      previousObj.current = {
        show:true,
        stuName:onPageRecs[cIndex-1].studentName,
        index:cIndex-1
      };
      nextObj.current = {
        show:true,
        stuName:onPageRecs[cIndex+1].studentName,
        index:cIndex+1};
    }    
    if(btn === "previous" || btn === "next"){
      setInitialData((previousState)=>{        
        const {candidateNumber,studentName,studentStateId,DOB,nationalId,cambridgeId} = onPageRecs[cIndex];        
        return{
          ...previousState,
          candNumber:candidateNumber,
          stuName:studentName,
          stuStateId:studentStateId,
          dob:DOB,
          natId:nationalId,
          caId:cambridgeId
        }
      })
      setRecComponents(onPageRecs[cIndex]?.components)
    }
    if(btn === "initial"){
      setRecComponents(onPageRecs[recIndex]?.components);
    }
  }

  const handleBackBtn = () => {
    if(reDirectTo){
      if(reDirectTo.includes('aice')){
        navigate(reDirectTo,{state:selectedParams});
      }else{
        navigate(reDirectTo);
      }
      dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:tab}));
    }else{
      navigate("/student-reports",{state:selectedParams});
    }
  };
  const downloadPdf = useReactToPrint({
    bodyClass:"m-2 p-2",
    documentTitle:'Pass_Rate_Report',
    content: () => studentDetialRef.current
  });

  const tabChange = (s) => {
    dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));    
    navigate(s.path);
  };

  const candidateHistory = async() =>{
    let params = {
      cambridgeId:initialData.caId,
      centreId:centreId,
      series:selectedParams?.series ? selectedParams.series:series,
      districtId:districtId,
      stateId:stateId,
      roleLevel:userInfo.roleLevel,
      roleName:"REPORT_MAKER_EXAMS_OFFICER",
      token:userInfo.totalLoggedInData.access_token,
      bpId:userInfo.orgs[0]?.bpid
    }    
    const resp = await getCandidateHistory(params);
    if(resp?.data?.length){
      setRecComponents(resp.data);
      historyCheck.current = true;
    }else if(resp?.error || !resp?.data?.length){
      dispatch(setAlert({message:constants.alertMsgs.noHistory,status:"error"}));
    }
  }

  return (
    <div ref={studentDetialRef} className="">
      <div className="row head_align">
          <div className="col-lg-6 col-md-8 col-sm-12">
              <div><label className="lbl text-start">{constants.studentReports.header}</label></div>                                                
          </div>
          <div className={`col-lg-6 col-md-4 col-sm-12 ${userInfo.smallScreen?"mt-2":""}`}>
              {userInfo.roleLevel !== "teacher" && 
                  <Button className={`h-auto text-start ${window.innerWidth > 991?'float-end':'mt-4 float-start'}`} onClick={() => tabChange({tab:0,path:"/dashboard"})}>{constants.dashboard.downloadIcon} {downloadBtn}</Button>
              }
          </div>
      </div>
      <div className={`col-lg-8 col-md-12 col-sm-12 text-start mb-4 ${userInfo.smallScreen?"mt-3":""}`}>{constants.studentReports.subHeader}</div>      
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 mt-3 d-inline-flex jsutify-content-start">
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            className="backBtnCls"
            onClick={handleBackBtn}
          >
            <SvgIcon className="backIconcls">
              {constants.studentReports.StudentDetail.icon.back}
            </SvgIcon>
            Back
          </Button>
        </div>
        <div className={`col-lg-8 col-md-8 col-sm-12 mt-3 d-inline-flex ${userInfo.smallScreen?"":"justify-content-end"}`}>
          <div className={`row ${userInfo.smallScreen?"text-start":"justify-content-end"}`}>
            {previousObj.current.show && <div className={`col-auto ${userInfo.smallScreen?"mb-3":""}`}><Button
              color="secondary"
              size="large"
              variant="outlined"
              className="text-dark"
              onClick={()=>studentSwitching('previous',previousObj.current.index)}
            >
              <p className="btns_txt mt-3">
                Previous:<div className="fw-bold mx-2 names_txt text-start">{previousObj.current.stuName}</div>
              </p>
            </Button></div>}
            {nextObj.current.show && <div className="col-auto"><Button
              color="secondary"
              size="large"
              variant="outlined"
              className="text-dark"
              onClick={()=>studentSwitching('next',nextObj.current.index)}
            >
              <p className="btns_txt mt-3">
              Next:<div className="fw-bold mx-2 names_txt text-start">{nextObj.current.stuName}</div>
              </p>
            </Button></div>}
          </div>
        </div>
      </div>
      <div>
        <Card className="mt-3 w-100 h-100">
          <CardContent>          
            <div className="row">
              <div className="col-lg-6 col-md-3 col-sm-12 d-flex flex-column">
                <h3 className="studentName">{initialData.stuName}</h3>
                <p className="py-0 schoolCls">{centreName.current}</p>
                <span className="tag">
                  Date of birth: <strong className="mx-2">{initialData.dob}</strong>
                </span>
                <span className="tag">
                  Candidate number: <strong className="mx-2">{initialData.candNumber}</strong>
                </span>
                <span className="tag">
                  State number: <strong className="mx-2">{initialData.stuStateId}</strong>    
                </span>
                <span className="tag pb-3">
                  National ID: <strong className="mx-2">{initialData.natId}</strong>
                </span>
              </div>
              <div className={`col-lg-6 col-md-9 col-sm-12 ${userInfo.smallScreen?"mb-3 text-start":"text-end"}`}>               
               <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadPdf}>Download (pdf)</Button>               
              </div>             
            </div>
            {/* {userInfo.roleLevel !== "teacher" && !historyCheck.current &&
             <div className="mt-3 mb-2 text-start"><input type="checkbox" onClick={candidateHistory} /><span style={{marginLeft:"2px"}}>Show candidate history</span></div>} */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {constants.studentReports.StudentDetail.tableHeaders.map(
                      (item, index) => {
                        return <TableCell key={index}>{item.label}</TableCell>;
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recComponents?.length>0 && recComponents?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item?.series}</TableCell>
                        <TableCell>{item?.syllabusCode}</TableCell>
                        <TableCell>{item?.syllabusName}</TableCell>
                        <TableCell>{item?.qualification === constants.dropDownList.qualsBySyllabus[0].value ?
                                    constants.dropDownList.qualsBySyllabus[0].label:item.qualification }</TableCell>
                        <TableCell>{item?.levelEntered? item.levelEntered:"-"}</TableCell>
                        <TableCell>{item?.levelAchieved? item.levelAchieved:"-"}</TableCell>
                        <TableCell>{item?.grade ? item.grade:"-"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default StudentDetail;
