import "./SyllabusReports.css";
import "../../common/global.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Card,
  Notification,
  Link,
} from "@cambridgeassessment/cambridge-ui";
import { constants } from "../../constants";
import { useEffect, useState, useRef } from "react";
import Spinner from "../../common/Spinner";
import { getSchoolsData, getStudentReportData, getSyllabusData, getSyllabusReportsData } from "../../services/muleSoftServices";
import { GrpDropDown, DropDown } from "../../common/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Banner from "../../common/Banner";
import { setAlert } from "../../redux/slice/GlobalMessage";
import { useReactToPrint } from "react-to-print";
import { setSchoolsData } from "../../redux/slice/dropDownsData";
import { useLocation, useNavigate } from "react-router-dom";
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { SvgIcon } from "@mui/material";
import { setDistrictNavs, setRecentSelectedSeries } from "../../redux/slice/passRate";
import { showPendingNotification } from "../../common/validations";
import { setSyllabusReport } from "../../redux/slice/syllabusReport";

const SyllabusReports = () => {
  const savedPassResp = useSelector((state) => state.pass),
        initialState = {
          centreDetails: {},
          spin: true,
          seriesSelectedValue: !isEmpty(savedPassResp.selectedSeries)?savedPassResp.selectedSeries:{},
          syllabusSelectedValue: {},
          qualificationSelectedValue:{},
          schoolSelectedValue:{},    
          sort:{key:"sylID"}
    },
    [components, setComponents] = useState([]),
    [initialData, setInitialData] = useState(initialState),
    dispatch = useDispatch(),
    navigate = useNavigate(),    
    userInfo = useSelector((state) => state.userInfo),    
    supportRole = useSelector((state) => state.new),
    savedSylRows = useSelector((state) => state.sylReport),
    syallbusRef = useRef(null),
    syllabusInitialized = useRef(false),
    centreIdFromDetailBack = useRef(""),
    seriesList = useRef([]),
    compHeaders = useRef([]),
    spinnerRef = useRef(""),
    enableQualsBySyllabus = useRef(false),
    downloadBtn = useRef(""),
    dropSelection = useRef(false),
    location = useLocation(),
    swapToExam = useRef(false),
    notificationStatus = useRef("pending"),
    [syllabusRes,setSyllabusRes] = useState([]),
    [schoolRes,setSchoolRes] = useState([]),
    {centreDetails,spin,seriesSelectedValue,syllabusSelectedValue,
     qualificationSelectedValue,schoolSelectedValue,sort} = initialData,
    {roleLevel,emlCentre,emlCentreObj,emlDistrict,uid,
     emlDistrictObj,emlState,totalLoggedInData,roleFromSAP,
     hierarchy,orgs,smallScreen,state,district} = userInfo,
    {seriesDropList,seriesLabels,districtNavs,selectedSeries} = savedPassResp,
    tabChange = (s) => {
      dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
      navigate(s.path);
    },    
    tableHeaders = (recs) => {
      let uniqueComponentNames = [];
      if(recs.length>0 && recs[0].components.length>0 && !isEmpty(recs[0].components[0].comp)){
        recs.forEach((r,i) => {        
          r.components.forEach((c,i) => {          
              if(uniqueComponentNames.length){
                let newComp = uniqueComponentNames.filter(u=>u === c.comp);
                if(!newComp.length){
                  uniqueComponentNames.push(c.comp)
                }
              }else{
                  uniqueComponentNames.push(c.comp)
              }
                      
          });
        });
      }
      
      const headers = uniqueComponentNames.map((u,i)=>{
        return (
          <TableCell
            className={i % 2 > 0 ? "col_odd_bg text-center h_max_width" : "text-center h_max_width"}
            key={i}
            colSpan={3}
          >
            {u}
          </TableCell>
        );
      })
      compHeaders.current = uniqueComponentNames;
      return headers;
    },
    tableSubHeads = () => {
      const subHeaders = compHeaders.current.map((r,i)=>{
        return constants.syllabusCompCols.map((s, j) => {
          return (
            <TableCell
              className={i % 2 > 0 ? "col_odd_bg text-center comp_col_width" : "text-center comp_col_width"}
              key={j}
              colSpan={1}
            >
              {s}
            </TableCell>
          );
        });
      })      
      return subHeaders;
    },
    componentColumnsData = (rec) => {
      const dataAsPerCompsOrder = compHeaders.current.map((h,i)=>{
        let eachCompData = rec?.components.filter(c=>c.comp === h);
          return constants.syllabusCompCols.map((s, j) => {
            if(eachCompData.length>0){
              return (
                <TableCell
                  className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"}
                  key={j + i}
                  colSpan={1}
                >
                  {((s.toLowerCase() === "mark" || s.toLowerCase() === "grade") && eachCompData[0][s.toLowerCase()] === null)?"-":
                  (s.toLowerCase() === "mark" && eachCompData[0][s.toLowerCase()] !== null)?
                  Number(eachCompData[0][s.toLowerCase()]).toFixed(1):
                  eachCompData[0][s.toLowerCase()]}
                </TableCell>
              );
            }else{
              return (
                <TableCell className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"} key={j + i} colSpan={1}>{"-"}</TableCell>
              );
            }
          });
        
      })
      return dataAsPerCompsOrder;
    };

    useEffect(()=>{
      window.scrollTo(0,0);
      const {qualsBySyllabus} = constants.dropDownList;
      if(!isEmpty(seriesDropList)){
        seriesList.current = seriesDropList;               
      }      
      if(!syllabusInitialized.current){
          let qual;
          syllabusInitialized.current = true;
          if(districtNavs.length === 3 || districtNavs.length === 1){
              const {params} = districtNavs[districtNavs.length-1];
              centreIdFromDetailBack.current = params.centreId;
              qual = params.qualification;
          }
          if((roleLevel === "district" || emlDistrict)){            
            if(location.state?.series){
              swapToExam.current = true;
            }        
            retrieveSchools([selectedSeries],!isEmpty(qual)?qual:qualsBySyllabus[0]);           
          }else{
            if(location.state?.qualification){
              qual = location.state.qualification.toLowerCase() !== "igcse"?qualsBySyllabus[0]:qualsBySyllabus[1]
            }
            retrieveSyllabus([selectedSeries],schoolSelectedValue?.centreID,!isEmpty(qual)?qual:qualsBySyllabus[0])
          }
      }
      if(roleLevel === 'exams' || roleLevel === "district" || 
        emlDistrict || emlCentre || roleLevel === 'head'){
        downloadBtn.current = "Go to data downloads";
      }
      // eslint-disable-next-line
    },[]);

    const retrieveSyllabus = async (series,centreId,qualification) =>{
      let syllabus={},modifiedParams;      
      if(roleLevel === "support"){
        modifiedParams = {
          centreId:emlCentre?emlCentreObj.centreId:""
        }
      }      
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      centreId:centreId?centreId:
                                supportRole.support?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                      series:series[0].label,
                      rLevel:roleLevel,
                      bpId:orgs[0]?.bpid,
                      districtId:district,
                      stateId:!isEmpty(state)?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId,
                      quals:qualification?.value},
              syllabusData = await getSyllabusData(params),
              sortedSyllabuses = syllabusData?.data?.data.sort((a, b) => { 
                return a[sort.key] - b[sort.key]; 
              });       
        if(districtNavs.length && districtNavs.length <= 3){          
          const {params} = districtNavs[districtNavs.length-1];
          let syl = sortedSyllabuses.filter(s=>s.sylID === params.sylId);
          if(syl.length>0){
            syllabus = syl[0];
          }
          if(districtNavs.length !== 2){
            let dNavs = [];
            dNavs = [...districtNavs];
            dNavs.pop();
            dispatch(setDistrictNavs({districtNavs:dNavs}));
          }
        }else if(location.state?.sylID && !dropSelection.current){
          let syl = sortedSyllabuses.filter(s=>s.sylID === location.state.sylID);
          if(syl.length>0){
            syllabus = syl[0];
          }
        }else if(sortedSyllabuses?.length){
          syllabus = sortedSyllabuses[0];
        }              
        setSyllabusRes(sortedSyllabuses);
        enableQualsBySyllabus.current = roleLevel !== "state" && !emlState;           
        if(!isEmpty(sortedSyllabuses)){                             
          syllabus = syllabus?.sylID?syllabus:sortedSyllabuses[0];
          setInitialData((previousState) => {
            return {
              ...previousState,
              syllabusSelectedValue:syllabus,
              seriesSelectedValue:series[0],
              qualificationSelectedValue:qualification
            };
          });
          if(savedSylRows.rows.length>0){
            setComponents(savedSylRows.rows);
            setInitialData((previousState) => {
              return {...previousState,spin:false};
            });
            dispatch(setSyllabusReport({sylReportRows:[]}));            
          }else{
            fetchSyllabusDetails(syllabus?.sylID,series[0].label,centreId);
          }
        }else{
          setComponents([]);
          dispatch(setSyllabusReport({sylReportRows:[]}));
          notificationStatus.current = showPendingNotification(series[0].label);
          spinnerRef.current?.spinnerOpen();
          setInitialData((previousState) => {
            return {
              ...previousState,
              spin:false,
              qualificationSelectedValue:qualification,
              seriesSelectedValue:(location.state?.series && !dropSelection.current) ? location.state.series[0]:series[0]
            };
          });
        }
    }

    const fetchSyllabusDetails = (syllabusId,seriesId,cId) =>{    
      if (dropSelection.current || syllabusInitialized.current) {
        getSyllabusReport(syllabusId,seriesId,cId);
      }
    }

    const getSyllabusReport = async (sylId,seriesId,cId) => {
      let centreId;
      const {syllabusReport} = constants.sideNav.roleBaseApiKeys;
      if(cId){
        centreId = cId;
      }else if(location.state?.centreId){
        centreId = location.state.centreId;
        location.state = {};
      }else if(!isEmpty(centreIdFromDetailBack.current)){
        centreId = centreIdFromDetailBack.current;
      }else if(schoolSelectedValue?.centreID){
        centreId = schoolSelectedValue.centreID;
      }else if(supportRole.support){
        centreId = emlCentre?emlCentreObj.centreId:emlDistrict?emlDistrictObj.centreId:"";
      }else{
        centreId = orgs[0]?.sourceSystems[0]?.orgId;
      }
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      centreId:centreId,
                      series:seriesId,
                      syllabusId:sylId,
                      bpId:orgs[0]?.bpid,
                      stateId:!isEmpty(state)?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId,
                      districtId:emlDistrict?emlDistrictObj.districtId:district,
                      email:totalLoggedInData.profile.email,
                      roleLevel:(roleLevel === "district" || emlDistrict) ? syllabusReport.exams:
                                syllabusReport[roleLevel]},                      
      syllabus = await getSyllabusReportsData(params);      
      if(syllabus?.error){
        dispatch(setAlert({message:constants.alertMsgs[syllabus.message],status:syllabus.type}));
      }else{
        let sortedRows;       
        if(syllabus?.data?.dataRows){        
          const { centreNumber, centreName, series, syllabusID, syllabusName } = syllabus.data;
            sortedRows = syllabus?.data?.dataRows?.sort((a, b) => {
              return a.candNumber - b.candNumber;
            });
          setInitialData((previousState) => {
            return {
              ...previousState,
              centreDetails: {
                centreNumber,
                centreName,
                series,
                syllabusID,
                syllabusName,
              },
            };
          });          
          setComponents(!isEmpty(sortedRows)?sortedRows:[]);          
          centreIdFromDetailBack.current = "";
        }else if(syllabus?.data?.length>0){
          bindDistrictLevelSyllabus(syllabus.data);
        }else{
          notificationStatus.current = showPendingNotification(seriesId);          
        }
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return {...previousState,spin: false};
        });
      }
    };

    const bindDistrictLevelSyllabus = (dc) =>{
      let sortedComps=[];
      if(dc.length>0){                
        dc.forEach(d=>{            
          sortedComps = !sortedComps.length?d.dataRows:[...sortedComps,...d?.dataRows]
        })
        sortedComps = sortedComps.sort((a,b)=>{
          return a.candNumber - b.candNumber;
        })      
        setComponents(sortedComps);        
      }      
    }

    const selectedOption = (key, li) => {
      dropSelection.current = true; 
      setInitialData((previousState) => {
        return { ...previousState, [key]: li, spin:true };
      });
      if(key === "schoolSelectedValue" && li?.centreID !== schoolSelectedValue?.centreID){
        retrieveSyllabus([seriesSelectedValue],li?.centreID,qualificationSelectedValue);
      }else if(key === "seriesSelectedValue" && li?.label !== seriesSelectedValue?.label){
        dispatch(setRecentSelectedSeries({userSelectedSeries:li}));
        retrieveSyllabus([li],schoolSelectedValue.centreID,qualificationSelectedValue);
      }else if(key === "qualificationSelectedValue" && li?.label !== qualificationSelectedValue?.label){
        retrieveSyllabus([seriesSelectedValue],schoolSelectedValue?.centreID,li);               
      }else if(key === "syllabusSelectedValue" && li?.sylID !== syllabusSelectedValue?.sylID){
        fetchSyllabusDetails(li.sylID,seriesSelectedValue?.label,"");
      }                      
    };

    useEffect(()=>{
      if(spin){
        spinnerRef.current.spinnerOpen();
      }
    },[spin])

    const downloadPdf = useReactToPrint({
      bodyClass:"m-2 p-2",
      documentTitle:'Syllabus_Report',
      content: () => syallbusRef.current
    });

    const getReportData = async (s) =>{
      const {syllabusReport} = constants.sideNav.roleBaseApiKeys,
            params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      centreId:schoolSelectedValue?.centreID?schoolSelectedValue.centreID:
                              (supportRole.support && emlCentre)? emlCentreObj.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                      series:s.series,
                      rLevel:(roleLevel === "district" || emlDistrict) ? syllabusReport.exams:
                            syllabusReport[roleLevel],
                      bpId:orgs[0]?.bpid,
                      stateId:!isEmpty(userInfo.state)?userInfo.state:
                              !isEmpty(userInfo.hierarchy) && userInfo.hierarchy[0]?.stateId,
                      districtId:(supportRole.support && emlDistrict)?emlDistrictObj.districtId:district,
                      candNumber:s.candNumber},           
      stuReport = await getStudentReportData(params);
      if(stuReport?.error){
        console.log(stuReport.error);
      }else{
        return stuReport;
      }
    }

    const studentTabChange = async (s) =>{
      setInitialData((previousState) => {
        return { ...previousState, spin:true };
      });     
      let details = await getReportData(s),dNavs = [];
      if(details?.data?.dataRows?.length>0){      
        const studentInfo = {
          currentStudent:details.data.dataRows[0],reDirectTo:"/syllabus-reports",tab:2,
          centName:isEmpty(schoolSelectedValue?.centreName)?emlCentreObj?.centreName:'',
          series:details.data.series,
          centreId:isEmpty(details.data.centreID)?emlCentreObj?.centreId:"",
          districtId:district,
          stateId:!isEmpty(state)?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId
        };
        dNavs = [...districtNavs];
        dNavs.push({step:s.step,params:{series:seriesSelectedValue.label,
                                        sylId:syllabusSelectedValue.sylID,
                                        qualification:qualificationSelectedValue,
                                        centreId:schoolSelectedValue.centreID}})
        dispatch(setDistrictNavs({districtNavs:dNavs}));
        dispatch(setSyllabusReport({sylReportRows:components}));
        navigate(s.path,{state:studentInfo});
        dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:3}));
      }else{
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return { ...previousState, spin:false };
        });
        dispatch(setAlert({message:constants.alertMsgs.noDetail,status:"error"}));
      }   
    }

    const backFromDistrictNavs = ()=>{
      setInitialData((previousState)=>{
        return {...previousState,spin:true}
      })
      if(districtNavs.length === 2){
        navigate(districtNavs[1].params.reDirectTo);
      }else{
        dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:1}));
        navigate("/pass-rates");
      }
      dispatch(setSyllabusReport({sylReportRows:[]}));
    }

    const retrieveSchools = async(series,qualification) =>{
        let selectedSchool=[],modifiedParams;        
        if(roleLevel === "support" && emlDistrict){
          modifiedParams = {
            districtId:emlDistrictObj.districtId,
            roleLevel:emlDistrictObj.emlLevel
          }
        }
        const {schoolList} = constants.sideNav.roleBaseApiKeys,
              params = {token:totalLoggedInData.access_token,
                        roleName:roleFromSAP,
                        districtId:(supportRole.support && modifiedParams.districtId)?modifiedParams.districtId:district,
                        roleLevel:schoolList[roleLevel],
                        bpId:orgs[0]?.bpid,
                        stateId:!isEmpty(state)?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId};        
        const schoolData = await  getSchoolsData(params),
              schoolArr = schoolData?.data?.data;
        if(!isEmpty(schoolArr)){
          let centre = !isEmpty(location.state?.centreId)?location.state.centreId:centreIdFromDetailBack.current;          
          selectedSchool = schoolArr.filter(a=>a.centreID === centre);
          let cId = selectedSchool.length>0?selectedSchool[0].centreID:schoolArr[0].centreID;
          retrieveSyllabus(series,cId,qualification);
          setSchoolRes(schoolArr);
          let schoolObj = selectedSchool.length>0?selectedSchool[0]:schoolArr?.length>0?schoolArr[0]:{};
          setInitialData((previousState)=>{
            return {...previousState,schoolSelectedValue:schoolObj}
          })
          dispatch(setSchoolsData({ schoolData:schoolArr}));    
      }      
    }

    return (
      <div>
        {spin ? (
          <Spinner ref={spinnerRef} />
        ) : (
          <div ref={syallbusRef}>
          <div className="row">
              <div className="col-lg-6 col-md-8 col-sm-12 text-uppercase mt-3 mb-3">
                  <div><label className="lbl text-start">{constants.syllabusReports.header}</label></div>                                                
              </div>
              <div className="col-lg-6 col-md-4 col-sm-12">
                  {roleLevel !== "teacher" && 
                      <Button className={`${window.innerWidth > 991?'mt-4 float-end':'mt-2 float-start'}`} onClick={() => tabChange({tab:0,path:"/dashboard"})}>{downloadBtn.current}</Button>
                  }
              </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 syllabus_sub_text text-start mt-2">
            {constants.syllabusReports.subHeader} 
            {"Get"} <a className="link" target="_blank" href={constants.syllabusReports.understandingResults}>
                        information on understanding results.
                      </a>
            {" Get information on"} <a className="link" target="_blank" href={constants.syllabusReports.aice_qulification}>
                        AICE Diploma grading.
                      </a>
          </div>

            <>
            <div className="row">
            {(roleLevel === 'district' || emlDistrict) && <div className="col-1 mt-20 text-start mb-2">
              <Button variant="outlined" color="primary" size="large" onClick={backFromDistrictNavs}>
                <SvgIcon className="backIconcls">{constants.studentReports.StudentDetail.icon.back}</SvgIcon>
              </Button>
            </div>}
            {(roleLevel === 'district' || emlDistrict) && 
              <div className={`col-md-12 col-sm-12 mb-2 ${window.innerWidth <820?"mt-2":""} ${enableQualsBySyllabus.current?"col-lg-3":"col-lg-4"}`}>
                <DropDown class="text-start"
                        label='SCHOOL'
                        userSelectedDropdown='schoolSelectedValue'
                        selectedValue={schoolSelectedValue?.centreID}
                        list={schoolRes.length?schoolRes:[]}
                        onChange={selectedOption}
                        option='centreID' value='centreName'
                        mixedStr={true}/>
              </div>}
              <div className={`col-md-12 col-sm-12 mb-2 ${window.innerWidth <820?"mt-2":""} ${enableQualsBySyllabus.current && 
                                roleLevel !== "exams" && !emlCentre &&
                                roleLevel !== "head" && roleLevel !== "teacher"?"col-lg-2":"col-lg-3"}`}>
                <GrpDropDown
                  class="text-start"
                  onChange={selectedOption}
                  selectedValue={seriesSelectedValue?.label}
                  userSelectedDropdown='seriesSelectedValue'
                  label={constants.syllabusReports.dropDownLabels.series}
                  list={seriesList.current}
                  option='label' value='label'/>           
              </div>
              <div className={`col-md-12 col-sm-12 mb-2 ${window.innerWidth <820?"mt-2":""} ${enableQualsBySyllabus.current && 
                                roleLevel !== "exams" && !emlCentre &&
                                roleLevel !== "head" && roleLevel !== "teacher"?"col-lg-2":"col-lg-3"}`}>
              <DropDown class="text-start" 
                        onChange={selectedOption}
                        selectedValue={qualificationSelectedValue?.value}
                        userSelectedDropdown='qualificationSelectedValue'
                        label={constants.passRate.dropDownLabels.qual}
                        list={constants.dropDownList.qualsBySyllabus}
                        option='value' value='label' />
              </div>
              <div className={`col-lg-4 col-md-12 col-sm-12 mb-2 ${window.innerWidth <820?"mt-2":""}`}>
                <DropDown
                  class="text-start"
                  userSelectedDropdown='syllabusSelectedValue'
                  selectedValue={syllabusSelectedValue?.sylID}
                  onChange={selectedOption}
                  label={constants.syllabusReports.dropDownLabels.qual}
                  list={syllabusRes}
                  mixedStr={true}
                  option='sylID' value='sylName'
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mb-2"></div>
            </div>
            {components.length>0 && <Card className="card_spacing mt-2" style={{height:'auto'}}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {syllabusRes.length>0 && <h3 className="card-header text-start mb-2">{syllabusSelectedValue?.sylID} - {syllabusSelectedValue?.sylName}</h3>}
                  <p className="mb-4 text-start card-sub-head">Cambridge International {qualificationSelectedValue?.label}</p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Button className={` mb-3 ${smallScreen?'float-start':'float-end'}`} variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadPdf}>
                      Download (pdf)
                  </Button>
                </div>
              </div>           
              <TableContainer>
                <Table aria-label="syllabus-table" className="t_border" style={{width:"16px"}}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="col_width"></TableCell>
                      <TableCell className="fg_bg_color text-center tot_col_width" colSpan={2}>
                        Final grades
                      </TableCell>
                      {components.length>0 && tableHeaders(components)}
                    </TableRow>
                    <TableRow>
                      <TableCell className="col_width">Students ({components.length})</TableCell>
                      <TableCell className="fg_bg_color text-center tot_col_width" colSpan={1}>
                        Total
                      </TableCell>
                      <TableCell className="fg_bg_color text-center tot_col_width" colSpan={1}>
                        Grade
                      </TableCell>
                      {components.length>0 && tableSubHeads()}
                    </TableRow>
                </TableHead>
                  <TableBody>
                    {components.length>0 && components.map((c, i) => {
                      return (
                        <TableRow key={i} hover={true}>
                          <TableCell
                            title={
                              c.candNumber + " - " + c.studentName
                            }
                            className="text-truncate col_width"
                          >
                            <Link className="studentNameCls" onClick={()=>studentTabChange({step:"step3",path:'/student-details',
                                                                                   series:seriesSelectedValue?.label,                                                                                   
                                                                                   candNumber:c.candNumber})}>{c.candNumber + " - " + c.studentName}</Link>
                          </TableCell>
                          <TableCell className="fg_bg_color text-center tot_col_width">
                            {c.finalTotal === null ? "-":Number(c.finalTotal).toFixed(1)}
                          </TableCell>
                          <TableCell className="fg_bg_color text-center tot_col_width">
                            {c.finalGrade === null ? "-":c.finalGrade}
                          </TableCell>
                          {componentColumnsData(c)}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>             
            </Card>}
            {notificationStatus.current === "pending" && !components.length && roleLevel !== 'teacher' && <Notification variant="info" className="mt-4">
                <p className="text-start mb-2"><strong>{seriesSelectedValue?.label} exams results pending</strong></p>
                <p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates</a>.</p>
                <p className="text-start" style={{marginBottom:"10px;"}}>Pass rate reports will be available after this time.</p>
              </Notification>}
            
            {notificationStatus.current === "no data" && !components.length && roleLevel !== 'teacher' &&
              <Notification variant="info" className="mt-4 text-start">Data not available for {seriesSelectedValue?.label} for the selected options</Notification>
            }
            {roleLevel === 'teacher' && !components.length &&
            <Banner content={constants.syllabusReportAlert}></Banner>}
            </>
          </div>
        )}
      </div>
    );
};
export default SyllabusReports;
