import { Navigation,NavigationItem } from '@cambridgeassessment/cambridge-ui';
import { useEffect, useRef, useState } from 'react';
import { constants } from '../constants';
import { useNavigate } from 'react-router-dom';
import './SideNavigation.css';
import {useSelector,useDispatch} from 'react-redux';
import {setTabNumber} from '../redux/slice/sideNavigation';
import '../common/global.css';
import { useAuth } from 'react-oidc-context';
import { setDistrictNavs } from '../redux/slice/passRate';
import { setSyllabusReport } from '../redux/slice/syllabusReport';

const SideNav = ({closeRespSideNav}) =>{
  const navigate = useNavigate(),
        dispatch = useDispatch(),
        auth = useAuth(),
        initialInactivity = useRef(false),
        nav = useSelector(state => state.nav),          
        userInfo = useSelector(state => state.userInfo),
        supportRole = useSelector((state) => state.new),
        [reportTabCount,setReportTabCount] = useState(nav.reportTab),
        [manageTabCount,setManageTabCount] = useState(nav.managementTab),
        reportLevel = useRef(""),
        managementLevel = useRef(""),
        navList = useRef([]),
        manageDataLinks = useRef([]),        

        reportTabChange = (s) =>{
          dispatch(setTabNumber({reportTabNumber:s.tab,managementTabNumber:-1}));
          setReportTabCount(s.tab);
          navigate(s.path);
          if(userInfo.smallScreen){
            closeRespSideNav();
          }
        },
        
        managementTabChange = (s) =>{
          if(userInfo.roleLevel === "district" || userInfo.emlDistrict){
            s.tab = 0
          }
          dispatch(setTabNumber({managementTabNumber:s.tab,reportTabNumber:-1}));
          setManageTabCount(s.tab);
          navigate(s.path);
          if(userInfo.smallScreen){
            closeRespSideNav();
          }
        };  
  
    let inactivityTime = function () { 
      let time;
      resetTimer();
  
      function resetTimer() { 
        clearTimeout(time); 
        time = setTimeout(newToken, 60000); 
      }
  
      function newToken(){
        if(auth?.user?.expired){
          navigate("/");
          console.log(auth.user.expired ,'expired');
        }
        else{
          console.log(auth.user.expired, 'not expired');
          resetTimer();
        }
      }    
    }; 
    
    if(!initialInactivity.current){
      initialInactivity.current = true;
      inactivityTime(); 
    }
    
    
    if((userInfo.roleLevel === "support" && supportRole.support) || userInfo.roleLevel !== "support"){
        let navItems,roleLevel;
        const {emlCentre,emlCentreObj,emlDistrict,emlDistrictObj,emlState,emlStateObj} = userInfo;
        if(supportRole.support && emlCentre){
          roleLevel = emlCentreObj.emlLevel;
        }else if(supportRole.support && emlDistrict){
          roleLevel = emlDistrictObj.emlLevel;
        }else if(supportRole.support && emlState){
          roleLevel = emlStateObj.emlLevel;
        }
        const {allReportLinks,reportLabels,linksOnRoleBase,allManagementLinks} = constants.sideNav;
        reportLevel.current = supportRole.support? reportLabels[roleLevel]:reportLabels[userInfo.roleLevel];
        managementLevel.current = supportRole.support? reportLabels.management[roleLevel]:reportLabels.management[userInfo.roleLevel];
        let onlyLinks = (supportRole.support && linksOnRoleBase[roleLevel])?linksOnRoleBase[roleLevel]:
                        linksOnRoleBase[userInfo.roleLevel]?linksOnRoleBase[userInfo.roleLevel]:
                        linksOnRoleBase.common;
        if(onlyLinks?.length){      
          navItems = onlyLinks.map((l,i)=>{
              let link = allReportLinks.filter(r=>r.label.toLowerCase() === l.toLowerCase());
              link[0].tab = i;
              return link[0];
          })
        }else{
          navItems = allReportLinks;
        }

        if(navItems.length) {
          let navigationLinks = navItems.map(s=>{
            return(<NavigationItem icon={s.icon} onClick={()=>reportTabChange(s)}  style={{height:'40px', borderRight:'1px solid #E0E0E0'}} key={s.label} label={s.label} />)
          });
          navList.current = navigationLinks;
        }

        let manageLinks = allManagementLinks.map(s=>{
          if((userInfo.roleLevel === "district" || supportRole.support && emlDistrict) && s.label.toLowerCase() === "upload district data"){        
            return(<NavigationItem icon={s.icon} onClick={()=>managementTabChange(s)}  style={{height:'40px', borderRight:'1px solid #E0E0E0'}} key={s.label} label={s.label} />)
          }
          else if((userInfo.roleLevel === "exams" || supportRole.support && emlCentre) && s.label.toLowerCase() !== "upload district data"){
            return(<NavigationItem icon={s.icon} onClick={()=>managementTabChange(s)}  style={{height:'40px', borderRight:'1px solid #E0E0E0'}} key={s.label} label={s.label} />)
          }
          else if(userInfo.roleLevel === "head" && s.label.toLowerCase() !== "upload district data"){
            return(<NavigationItem icon={s.icon} onClick={()=>managementTabChange(s)}  style={{height:'40px', borderRight:'1px solid #E0E0E0'}} key={s.label} label={s.label} />)
          }
        });
        let nonEmpty = manageLinks.filter(m=>m !== undefined);
        manageDataLinks.current = nonEmpty;
    }
    

    if((userInfo.roleLevel === "support" && supportRole.support) || userInfo.roleLevel !== "support"){
      const {emlCentre,emlCentreObj,emlDistrict,districtName,stateName,emlState} = userInfo;
      let centreName,centreId;
      if(supportRole.support && emlCentre){
        centreName = emlCentreObj.centreName;
        centreId = emlCentreObj.centreId;
      }else if(supportRole.support && emlDistrict){
        centreName = districtName;
        centreId = stateName;
      }else if(supportRole.support && emlState){
        centreName = stateName;
        centreId = "united states";
      }
      return (
        <div className='side_nav_comp'>
          {userInfo.roleLevel === "support" && 
              <p className='text-start pt-4'><div className='centre_text'><strong>{centreName}</strong></div><br/>
              <span className='main_label txt_captialize'>{centreId}</span></p>}

          {(userInfo.roleLevel === "exams" || userInfo.roleLevel === "head" || userInfo.roleLevel === "teacher") &&
          <p className='text-start pt-4'><div className='centre_text'><strong>{userInfo?.orgs[0]?.name}</strong></div><br/>
          <span className='main_label'>{userInfo.orgs[0]?.sourceSystems[0]?.orgId}</span></p>}

          {userInfo.roleLevel === "state" && 
          <p className='text-start pt-4'><div className='centre_text'><strong>{stateName}</strong></div><br/>
          <span className='main_label txt_captialize'>{"United States"}</span></p>}

          {userInfo.roleLevel === "district" && 
          <p className='text-start pt-4'><div className='centre_text'><strong>{districtName}</strong></div><br/>
          <span className='main_label'>{stateName}</span></p>}

          <hr/><div className='main_label text-start'>{reportLevel.current}</div><hr/>
          <Navigation value={{currentTab:reportTabCount !== nav.reportTab? nav.reportTab:reportTabCount}} vertical>{navList.current}</Navigation>
          {manageDataLinks.current.length>0 && <hr/>}<div className='main_label text-start'>{managementLevel.current}</div>{manageDataLinks.current.length>0 && <hr/>}
          <Navigation value={{currentTab:manageTabCount !== nav.managementTab? nav.managementTab:manageTabCount}} vertical>{manageDataLinks.current}</Navigation>
        </div>
      )
    }
    else{
      return(
        <>
          <p className='text-start pt-4'><div className='centre_text'><strong>{"CUPA Admin"}</strong></div><br/>
          <span className='main_label txt_captialize'>{"United States"}</span></p>

          <hr className='support_line'/>
        </>
      )
    }
}
export default SideNav;